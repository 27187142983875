import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

function CodeRevealer({ utility }) {
  const [showCode, setShowCode] = useState(false);

  const revealCode = (e) => {
    e.preventDefault();
    if (showCode) {
      setShowCode(false);
    } else {
      setShowCode(true);
    }
  };

  const copyToClipboard = (voucher, url, e) => {
    e.preventDefault();
    navigator.clipboard.writeText(voucher.promoCode);
    toast.success('Promo code has been copied to clipboard.');
    setTimeout(() => {
      window.open(url, '_blank');
    }, 2000);
  };

  return (
    <div>
      {!showCode
      && <a href="#" onClick={(e) => revealCode(e)} className="button-collection" target="_blank" rel="noreferrer">{utility.linkLabel || 'Claim the offer'}</a>}
      {showCode
      && (
      <div className="utility-item__voucher-slot">
        <input className="utility-item__voucher-input" type="text" readOnly value={utility.promoCode} />
        <button
            type="button"
            className="utility-item__voucher-button"
            onClick={(e) => copyToClipboard(utility, utility.linkUrl, e)}>
          {process.env.CUSTOM_COPY_ICON
            ? <img src={process.env.CUSTOM_COPY_ICON} width="14" alt="copy" />
            : <img src="https://assets.heyethos.com/ethos/ui/icon-copy.svg" width="14" alt="copy" />}
        </button>
      </div>
      )}
    </div>
  );
}

export default CodeRevealer;
