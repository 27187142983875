import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getUtilityType } from '../../managers/Helpers';
import AccountManager from '../../managers/Account';
import SimpleModal from '../modal/modal';
import Throbber from '../throbber';
import CreatorManager from '../../managers/Creator';

const UtilityDisplay = ({
  nftId, nftUtility, collectionUtility, onPlay,
}) => {
  const [formattedUtilities, setFormattedUtilities] = useState([]);
  const [formattedNFTUtilities, setFormattedNFTUtilities] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const formatUtilities = (utilities, type) => {
    const newArray = [];
    const utilitiesMax = 5;

    if (type === 'collection') {
      setFormattedUtilities(utilities);
    } else {
      setFormattedNFTUtilities(utilities);
    }
  };
  const getGatedVideo = async (gatedContentId, e) => {
    e.preventDefault();
    setShowModal(true);
    setTimeout(async () => {
      const gatedContent = await AccountManager.getGatedContentV2(AccountManager.getToken(), gatedContentId);
      setShowModal(false);
      if (gatedContent && gatedContent.success) {
        onPlay(gatedContent.fileUrl);
      } else {
        toast.error('Oops. Something went wrong. Please try again later.');
      }
    }, 3000);
  };

  const generateCommerceUrl = async (gatedCommerceId, e) => {
    e.preventDefault();
    setShowModal(true);
    const generatedCommerceUrl = await CreatorManager.generateGatedCommerceUrl(gatedCommerceId, nftId, AccountManager.getToken());
    if (generatedCommerceUrl && generatedCommerceUrl.success) {
      window.open(generatedCommerceUrl.draftOrderUrl, '_blank');
      setShowModal(false);
    } else {
      toast.error(generatedCommerceUrl.message || 'Oops. Something went wring generating your link. Please try again later.');
      setShowModal(false);
    }
  };

  const copyToClipboard = (voucher, e) => {
    e.preventDefault();
    navigator.clipboard.writeText(voucher.voucherCode);
    toast.success('Voucher has been copied to clipboard.');
  };

  useEffect(() => {
    formatUtilities(collectionUtility, 'collection');
    formatUtilities(nftUtility, 'nft');
  }, []);
  return (
    <div className="nft-details__utility-section">
      <SimpleModal isOpen={showModal} onBeforeClose={() => setShowModal(false)}>
        <Throbber throbberText="Loading media..." />
      </SimpleModal>
      {formattedUtilities.map((utility, index) => (
        <div className="nft-details__utility-tile" key={utility.utilityId}>
          {getUtilityType(utility.utilityType) === 'Generic Utility'
            && (
            <>
              {utility.genericPromotions.map((promo, index) => (
                <div className="utility-card" key={promo.genericPromotionId}>
                  <div
                    className="utility-card__thumbnail -no-text"
                    style={{
                      backgroundImage: 'url(' + promo.thumbnailUrl + ')',
                    }}>
                    <a href={promo.linkUrl} target="_blank" rel="noreferrer">
                      View
                    </a>
                  </div>
                  <a className="utility-card__title" href={promo.linkUrl} target="_blank" rel="noreferrer">{utility.utilityDescription}</a>
                  <div className="utility-card__description">{promo.description}</div>
                  {promo.linkUrl
                  && <a className="utility-card__claim-link" href={promo.linkUrl} target="_blank" rel="noreferrer">{promo.linkLabel || 'More info'}</a>}
                </div>
              ))}
            </>
            )}
          {getUtilityType(utility.utilityType) === 'Gated Commerce'
            && (
            <>
              {utility.gatedCommerces.map((gate, index) => (
                <div className="utility-card" key={gate.gatedCommerceId}>
                  <div
                    className="utility-card__thumbnail -no-text"
                    style={{
                      backgroundImage: 'url(' + gate.productThumbnail + ')',
                    }}>
                    {gate.ecommerceProductUrl && gate.contentType === 1
                      && (
                      <a href={gate.ecommerceProductUrl} target="_blank" rel="noreferrer">
                        Claim
                      </a>
                      )}
                    {gate.contentType === 2
                      && (
                      <a onClick={(e) => generateCommerceUrl(gate.gatedCommerceId, e)} href="#">
                        Claim
                      </a>
                      )}
                  </div>
                  {gate.ecommerceProductUrl && gate.contentType === 1
                     && <a className="utility-card__title" href={gate.ecommerceProductUrl} target="_blank" rel="noreferrer">{utility.utilityDescription}</a>}
                  {!gate.ecommerceProductUrl && gate.contentType === 1
                     && <div className="utility-card__title">{utility.utilityDescription}</div>}
                  {gate.contentType === 2
                    && <a onClick={(e) => generateCommerceUrl(gate.gatedCommerceId, e)} href="#" className="utility-card__title">{utility.utilityDescription}</a>}
                  
                  <div className="utility-card__description">{gate.ecommerceProductDescription}</div>
                  {gate.contentType === 2
                  && (
                  <>
                    {process.env.CUSTOM_CLAIM_OFFER
                      ? (
                        <a onClick={(e) => generateCommerceUrl(gate.gatedCommerceId, e)} className="utility-card__claim-image-link" href="#">
                          <img src="https://assets.heyethos.com/heath/button-claim.png" alt="claim offer" />
                        </a>
                      )
                      : <a onClick={(e) => generateCommerceUrl(gate.gatedCommerceId, e)} className="utility-card__claim-link" href="#">Claim offer</a>}
                  </>
                  )}
                  {gate.contentType === 1 && gate.ecommerceProductUrl
                  && (
                  <>
                    <a className="utility-card__claim-link" href={gate.ecommerceProductUrl} target="_blank" rel="noreferrer">More info</a>
                  </>
                  )}
                </div>
              ))}
            </>
            )}
          {getUtilityType(utility.utilityType) === 'Video Content'
            && (
            <>
              {utility.gatedContents.map((gate, index) => (
                <div className="utility-card" key={gate.gatedContentId}>
                  <div
                    className="utility-card__thumbnail -no-text"
                    style={{
                      backgroundImage: 'url(' + gate.thumbnail + ')',
                    }}>
                    <a href="#" onClick={(e) => getGatedVideo(gate.gatedContentId, e)}>
                      <img className="utility-icon -video" src="https://assets.heyethos.com/ethos/ui/icon-play-button.svg" alt="" />
                    </a>
                  </div>
                  <a className="utility-card__title" href="#" onClick={(e) => getGatedVideo(gate.gatedContentId, e)}>{gate.description}</a>
                  <div className="utility-card__description">{utility.utilityDescription}</div>
                  <a onClick={(e) => getGatedVideo(gate.gatedContentId, e)} className="utility-card__claim-link" href="#">Watch video</a>
                </div>
              ))}
            </>
            )}
        </div>
      ))}
      {formattedNFTUtilities.map((utility, index) => (
        <div className="nft-details__utility-tile" key={utility.utilityId}>

          {getUtilityType(utility.utilityType) === 'Gated Commerce' && (
            <>
              {utility.gatedCommerces.map((gate, index) => (
                <div className="utility-card" key={gate.gatedCommerceId}>
                  <div
                    className="utility-card__thumbnail -no-text"
                    style={{
                      backgroundImage: 'url(' + gate.productThumbnail + ')',
                    }}>
                    <a onClick={(e) => generateCommerceUrl(gate.gatedCommerceId, e)} href="#">
                      Claim
                    </a>
                  </div>
                  <a onClick={(e) => generateCommerceUrl(gate.gatedCommerceId, e)} href="#" className="utility-card__title">{utility.utilityDescription}</a>
                  <div className="utility-card__description">{gate.ecommerceProductDescription}</div>
                  <>
                    {process.env.CUSTOM_CLAIM_OFFER
                      ? (
                        <a onClick={(e) => generateCommerceUrl(gate.gatedCommerceId, e)} className="utility-card__claim-image-link" href="#" target="_blank" rel="noreferrer">
                          <img src="https://assets.heyethos.com/heath/button-claim.png" alt="claim offer" />
                        </a>
                      )
                      : <a onClick={(e) => generateCommerceUrl(gate.gatedCommerceId, e)} className="utility-card__claim-link" href="#">Claim offer</a>}
                  </>
                </div>
              ))}
            </>
          )}

          {getUtilityType(utility.utilityType) === 'Coupon Voucher' && (
            <>
              {utility.vouchers.map((voucher, index) => (
                <div className="utility-card" key={voucher.voucherId}>
                  <div
                    className="utility-card__thumbnail -no-text"
                    style={{
                      backgroundImage: 'url(' + voucher.voucherImageUrl + ')',
                    }}>
                    <a href={voucher.productUrl} target="_blank" rel="noreferrer">
                      Claim
                    </a>
                  </div>
                  <a href={voucher.productUrl} target="_blank" rel="noreferrer" className="utility-card__title">{utility.utilityDescription}</a>
                  <div className="utility-card__description">{voucher.description}</div>
                  <div className="utility-card__voucher-slot">
                    <input className="utility-card__voucher-input" type="text" readOnly value={voucher.voucherCode} />
                    <button
                      type="button"
                      className="utility-card__voucher-button"
                      onClick={(e) => copyToClipboard(voucher, e)}>
                      {process.env.CUSTOM_COPY_ICON
                        ? <img src={process.env.CUSTOM_COPY_ICON} width="14" alt="copy" />
                        : <img src="https://assets.heyethos.com/ethos/ui/icon-copy.svg" width="14" alt="copy" />}
                      
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}

          {/* {utility.map((item) => (
            <div key={item.utilityId}>
              {item.utilityType === 1
                ? (
                  <div>
                    {item.vouchers.map((voucher) => (
                      <div key={voucher.voucherId}>
                        <div>
                          {item.utilityDescription}
                          :
                        </div>
                        <div>
                          {voucher.voucherCode}
                        </div>
                      </div>
                    ))}
                  </div>
                )
                : <a href="#" onClick={(e) => getGatedVideo(item, e)}>{item.utilityDescription}</a>}
            </div>
          ))} */}
        </div>
      ))}
    </div>
  );
};
export default UtilityDisplay;
